/**
 * templates/albums.js --- Template for all unique album pages
 *
 * This file is a template for generating album pages for
 * each unique event, through the createPages function
 * in gatsby-node.js
 */
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/seo"

import AlbumsInfo from "../components/albums/albums-info.js"
import AlbumsList from "../components/albums/albums-list.js"

/**
 * ql_albums: Contains the list of all Wet Ink albums
 * plus the individual album descriptions based on
 * page slug
 */
export const ql_albums = graphql`
  query($slug: String!) {
    # List of albums displayed on the left side of the page
    allContentfulAlbum(sort: { fields: date, order: DESC }) {
      edges {
        node {
          title
          artist
          date
          slug
          albumCover {
            file {
              url
            }
          }
          url
          description {
            json
          }
        }
      }
    }

    # Info for individual album based on slug
    contentfulAlbum(slug: { eq: $slug }) {
      title
      description {
        json
      }
    }
  }
`
/**
 * Events (Default): Component for the content
 * of the album page
 */
const Albums = props => {
  return (
    <Layout>
      <SEO title="Albums" />
      <AlbumsInfo albumData={props.data.contentfulAlbum.description.json}
        albumTitle={props.data.contentfulAlbum.title.toUpperCase()} />
      <AlbumsList albumData={props.data.allContentfulAlbum.edges} />
    </Layout>
  )
}

export default Albums
